<template>
  <div>
    <div class="main-container">

      <div class="main-title">테마 선주문 신청 내역</div>

      <div class="menu-title columns is-multiline">
        <div class="column is-6 lp-menu">신청 내용</div>
        <div class="column is-2 lp-menu">예상 견적</div>
        <div class="column is-2 lp-menu">예상 제작기간</div>
        <div class="column is-2 lp-menu">진행상태</div>
      </div>

      <div class="space-top"></div>

      <div class="col-wrap">
        <div v-for="(val,rowIdx) in value" class="lp-row-bottom" :key="'row-'+rowIdx">
          <div class="columns is-multiline">
            <!-- 신청내용 -->
            <div class="column is-6">
              <!-- 상품들 -->
              <div class="lp-inquiry-content" v-for="(prod, idx) in val.products"
                   :key="'product-'+idx">
                <div class="lp-cat">{{ prod.cat }}</div>
                <div class="flex" style="justify-content: space-between">
                  <div v-if="prod.cat==='주문전환'">{{ prod.text }}</div>
                  <div v-else>{{ prod.name }}</div>
                  <div>{{ productPrice(prod) | currency }}</div>
                </div>

              </div>

              <!-- 맞춤 디자인 -->
              <div class="lp-inquiry-content">
                <div class="lp-cat">맞춤 디자인</div>
                <div class="flex" v-if="val.main_upload">
                  <div>메인 페이지</div>
                  <div v-for="(file,idx) in val.main_upload" :key="'upload-'+idx">
                    <a :href="file" target="_blank">
                      <span class="lp-file-design">메인 페이지 양식 {{ idx === 0 ? '이미지' : '파일'}}</span>
                    </a>
                  </div>
                </div>
                <div class="flex" v-if="val.product_upload">
                  <div>상품 목록 페이지</div>
                  <div v-for="(file,idx) in val.product_upload" :key="'product-upload-'+idx">
                    <a :href="file" target="_blank">
                      <span class="lp-file-design">메인 페이지 양식 {{ idx === 0 ? '이미지' : '파일'}}</span>
                    </a>
                  </div>
                </div>
                <div class="flex" v-if="val.product_detail_upload">
                  <div>상품상세 페이지</div>
                  <div v-for="(file,idx) in val.product_detail_upload" :key="'product-detail--upload-'+idx">
                    <a :href="file" target="_blank">
                      <span class="lp-file-design">메인 페이지 양식 {{ idx === 0 ? '이미지' : '파일'}}</span>
                    </a>
                  </div>
                </div>
                <div class="flex" v-if="val.blank_upload">
                  <div>{{val.blank_page}} 페이지</div>
                  <div v-for="(file,idx) in val.blank_upload" :key="'blank-upload-'+idx">
                    <a :href="file" target="_blank">
                      <span class="lp-file-design">메인 페이지 양식 {{ idx === 0 ? '이미지' : '파일'}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- 예상견적 -->
            <div class="column is-2 lp-content-center">
              {{ monthPrice(val.products) | currency }}/매월
            </div>
            <!-- 예상 제작기간 -->
            <div class="column is-2 lp-content-center">
              33일
            </div>
            <!-- 진행상태 -->
            <div class="column is-2 lp-content-center">
              {{ convertStatus(val.status, value.service_status) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "ThemeRequestDetail",
    data() {
      return {
        value: {}
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      monthPrice(prods) {
        console.log(prods)
        let total = 0;

        prods.forEach(prod=>{
          if(prod.id>0) total+= this.getMonthPrice(prod.options);
        })

        return total;
      },
      productPrice(prod) {
        let price=0;
        prod.options.forEach(item=>{
          if(item.type===1) price = item.price;
        })
        return price;
      },
      // type === 0 : 1회결제, type === 1 : 한달
      getMonthPrice(options) {
        //console.log(options)
        let price = 0;
        options.forEach(option=>{
          if(option.type===1) {
            price = option.price;
          }
        })
        return price;
      },
      getDetail() {
        this.$axios.get(`user/${this.$store.getters.user.user_id}/launchpack/theme/preorder/request`)
          .then(res=>{
            this.value = res.data.data;
          })
      },
      convertStatus(status, service_status){
        let value = '';
        if (status===0) value = '신청미확인';
        else if (status===1) value = '신청확인';
        else if (status===2) value = '상담완료';
        else if(status===2 && service_status===1) value = '서비스등록'
        else if(status===2 && service_status===2) value = '상담완료'
        return value;
      }
    }
  }
</script>
<style scoped>
  .lp-file-design{
    margin-left: 6px;
    color: #7ad4ef;
  }
  .lp-content-center{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #393e46;
  }
  .lp-cat{
    color: #c7c7c7;
  }

  .lp-inquiry-content{
    padding: 12px 0;
    border-bottom: 1px solid #e6e6e6;
  }

  .lp-inquiry-content:last-child{
    border-bottom: 0
  }

  .lp-row-bottom {
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
  }

  .lp-row-bottom:last-child {
    border-bottom: 0;
  }

  .main-container {
    width: 100%;
    height: 100%;
  }

  .menu-title{
    width: 1080px;
    height: 56px;
    background-color: #f5f5f5;
    margin: 0 auto;
    display: flex;
  }

  .main-title{
    height: 27px;
    font-size: 18px;
    text-align: center;
    line-height: 27px;
    color:#393e46;
    margin:175px 0 40px 0;
    font-weight: 900;
  }

  .lp-menu{
    background-color: #f5f5f5;
    height: 100%;
    line-height: 33px;
    letter-spacing: normal;
    text-align: center;
    font-size: 13px;
    color: #989898;
  }

  .lp-menu:first-child{
    text-align: left;
  }

  .space-top{
    width: 1080px;
    height: 20px;
    margin: 0 auto;
    /*border:1px solid grey;*/
  }

  .col-wrap{
    margin: auto;
    height:100%;
    margin-bottom:80px;
    width: 1080px;
  }

  @media(max-width:768px) {
    .col-wrap {
      width: 74%;
    }
    .menu-title {
      display:none;

    }
  }


    @media(min-width:1024px){
    .col-wrap {
      width: 1080px;
    }

  }

  .service-name{
    height: 27px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    color: #393e46;
    margin:27px 0 27px 0;

  }

</style>
